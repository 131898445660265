export interface PermissionModel {
  permissionID: number;
  name: string;
  groupID: number;
  groupName: string;
  parentGroupID: number;
}

export enum PermissionsEnum {
  MONITORING_VIEW_DASHBOARD = 1, //*users that don’t have this permission should not see the option in side menu
  MONITORING_VIEW_FLOW_LIBRARY = 2, // *users that don’t have this permission should not see the option in side menu
  FLOW_CREATE_FLOW = 3,
  FLOW_VIEW_FLOW = 4,
  FLOW_EDIT_FLOW = 5,
  FLOW_ACTIVATE_FLOW = 6,
  FLOW_DEACTIVATE_FLOW = 7,
  FLOW_CLONE_FLOW = 8,
  FLOW_VIEW_LOG_HISTORY = 9,
  CHANGE_PASSWORD = 12,
  VIEW_CONNECTED_APPS = 13,
  EDIT_APP_CONNECTION = 14,
  DELETE_APP_CONNECTION = 15,
  DISABLE_APP_CONNECTION = 16,
  VIEW_ACTIONS_AND_EVENTS = 17,
  SETTINGS_VIEW_TIME_ZONE_SETTINGS = 18,
  SETTINGS_MODIFY_TIME_ZONE_SETTINGS = 19,
  SETTINGS_VIEW_ERROR_ALERTS = 46,
  SETTINGS_MODIFY_ERROR_ALERTS = 47,
  SETTINGS_VIEW_USAGE_ANOMALIES_ALERTS = 48,
  SETTINGS_MODIFY_USAGE_ANOMALIES_ALERTS = 49,
  SETTINGS_VIEW_VOLUME_CHECKS_ALERTS = 50,
  SETTINGS_MODIFY_VOLUME_CHECKS_ALERTS = 51,
  VIEW_PERIODIC_ALERTS_SETTINGS = 20,
  MODIFY_PERIODIC_ALERTS_SETTINGS = 21,
  VIEW_EVENT_LOG = 22,
  SEARCH_USER = 23,
  CREATE_USER = 24,
  MODIFY_USER = 25,
  SEND_PASSWORD = 26,
  DEACTIVATE_USER_ACCOUNT = 27,
  ACTIVATE_USER_ACCOUNT = 28,
  VIEW_API_TOKENS = 29,
  GENERATE_API_TOKEN = 30,
  DELETE_API_TOKEN = 31,
  REPORT_ACCESS_FLOW_REPORTS = 10,
  REPORT_ACCESS_FLOW_PERIODIC_REPORT = 11,
  REPORT_VIEW_BUSINESS_REPORTS = 32,
  REPORT_ACCESS_KYC_PERIODIC_REPORT = 33,
  REPORT_ACCESS_KYC_VERIFICATIONS_REPORT = 34,
  REPORT_ACCESS_FLOW_DETAILS_REPORT = 43,
  REPORT_ACCESS_AGENT_PERFORMANCE_METRICS_BY_VERIFICATION = 44,
  REPORT_ACCESS_AVERAGE_AGENT_PERFORMANCE_METRICS = 45,
  KYC_VIEW_USER_MANAGEMENT = 35,
  KYC_VIEW_VERIFICATIONS_MANAGEMENT = 36,
  KYC_VIEW_REVIEW_VERIFICATIONS = 37,
  KYC_VIEW_DASHBOARD = 38,
  KYC_CHANGE_VERIFICATION_STATUS = 39,
  KYC_ASSIGN_VERIFICATIONS = 40,
  KYC_VIEW_ASSIGNMENTS_MONITOR = 41,
  CRM_FLOW_BULK_UPDATE = 42,
}
